/** @jsx jsx */

import { jsx } from 'theme-ui'

import routes from '../constants/routes'

export const halo = {
  title: `Terms and conditions`,
  route: routes.terms,
}

export const intro = {
  ...halo,
}

export const content = (
  <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, py: 5, px: 4 }}>
    <div sx={{ maxWidth: `70ch` }}>
      <h2>Introduction</h2>
      <p>
        Carta Capital Markets, LLC (“CCMX”), a wholly-owned subsidiary of Carta, Inc. (“CINC”), is an SEC registered
        broker-dealer and member of the Financial Industry Regulatory Authority (“FINRA”) and the Securities Investor
        Corporation (“SIPC”) that provides certain online stock brokerage services to certain self-directed investors.
      </p>
      <p>
        These Terms and Conditions are in addition to any other agreements between customer (“you”) and CCMX and CINC
        (collectively, “Carta”), including any customer or account agreements and any other agreements that govern your
        use of software, products, goods, services, content, tools, and information provided by Carta.
      </p>

      <h2>General</h2>
      <p>
        The CCMX website (the “Service”) may include or make available certain content (the “Content”). Content may
        include, without limitation: general news and information, commentary, research reports, educational material
        and information and data concerning the financial markets, securities and other subjects; financial and
        investment interactive tools, such as alerts or calculators; and any other information, content, services, or
        software. Certain Content is furnished by third parties (each, a “Third Party Provider”). Such Content (“Third
        Party Content”) includes, without limitation, any information, content, service or software made available by or
        through social media websites, blogs, wikis, online conferences, telecasts, podcasts, and other forums
        (collectively, the “Forums”). Third Party Content may be available through framed areas or through hyperlinks to
        the Third Party Providers’ websites.
      </p>

      <h2>Acceptance of Terms and Conditions</h2>
      <p>
        By using the Service and the Content, you agree to follow and be bound by these Terms and Conditions, including
        the policies referenced herein. Customers of Carta are granted additional levels of access to the website and
        their relationship with CCMX is governed by additional agreements and terms of use.
      </p>

      <h2>Disclaimer, Limitation of Liability, and Indemnification</h2>
      <p>
        The Content and the Service are provided on an “as is” and “as available” basis. To the fullest extent permitted
        under applicable law, CCMX and the Third Party Providers expressly disclaim all warranties of any kind with
        respect to the Content and the Service, whether express or implied, including, but not limited to, the implied
        warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Carta nor Third
        Party Providers guarantee the accuracy, timeliness, completeness or usefulness of any Content. You agree to use
        the Content and the Service only at your own risk. Neither Carta nor the Third Party Providers explicitly or
        implicitly endorse or approve any Third Party Content. Third Party Content is provided for informational
        purposes only.
      </p>
      <p>
        The Content is not intended to provide financial, legal, tax or investment advice or recommendations. You are
        solely responsible for determining whether any investment, investment strategy or related transaction is
        appropriate for you based on your personal investment objectives, financial circumstances and risk tolerance.
        You should consult your legal or tax professional regarding your specific situation. CCMX AND THE THIRD PARTY
        PROVIDERS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
        INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, REVENUE, INCOME, GOODWILL, USE, DATA OR OTHER
        INTANGIBLE LOSSES (EVEN IF CARTA OR ANY THIRD PARTY PROVIDER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES), RESULTING FROM: (1) THE USE OF OR THE INABILITY TO USE THE CONTENT OR THE SERVICE; (2) THE COST OF
        PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED
        OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE SERVICE; (3) ACCESS TO OR
        ALTERATION OF YOUR ACCOUNT, TRANSMISSIONS OR DATA DUE TO YOUR CONDUCT, INACTION OR NEGLIGENCE; OR (4) ANY OTHER
        MATTER RELATING TO THE CONTENT OR THE SERVICE.
      </p>
      <p>
        You will not hold Carta and/or any Third Party Provider liable in any way for (a) any inaccuracy of, error or
        delay in, or omission of the Content; or (b) any loss or damage arising from or occasioned by i) any error or
        delay in the transmission of such Content; ii) interruption in any such Content due either to any negligent act
        or omission by any party to any “force majeure” (e.g., flood, extraordinary weather conditions, earthquake or
        other act of God, fire, war, insurrection, riot, labor dispute, accident, action of government, communications
        or power failure, equipment or software malfunction), or iii) any other cause beyond the reasonable control of
        Carta and/or Third Party Provider, or iv) non-performance.
      </p>
      <p>
        You will indemnify and hold harmless Carta and the Third Party Providers, and the officers, directors, agents,
        partners, employees, licensors, distributors, and representatives of Carta and the Third Party Providers, from
        and against any and all claims, demands, actions, causes of action, suits, proceedings, losses, damages, costs,
        and expenses, including reasonable attorneys' fees, arising from or relating to your access and/or use of, or
        interaction with the Content (including, without limitation, Third Party Content), or any act, error, or
        omission of your use of your account or any user of your account, in connection therewith, including, but not
        limited to, matters relating to incorrect, incomplete, or misleading information; libel; invasion of privacy;
        infringement of a copyright, trade name, trademark, service mark, or other intellectual property; any defective
        product or any injury or damage to person or property caused by any products sold or otherwise distributed
        through or in connection with the Service; or violation of any applicable law.
      </p>

      <h2>Content</h2>
      <p>
        Content posted on the Service is published as of its stated date or, if no date is stated, the date of first
        posting. Neither Carta nor the Third Party Providers have undertaken any duty to update any such information.
      </p>
      <p>
        Carta does not prepare, edit, or endorse Third Party Content. CCMX does not guarantee the accuracy, timeliness,
        completeness or usefulness of Third Party Content, and is not responsible or liable for any content,
        advertising, products, or other materials on or available from third party sites.
      </p>
      <p>
        Neither CCMX nor the Third Party Providers make any representations, warranties or other guarantees as to the
        accuracy or timeliness of any price quotes. Neither Carta nor the Third Party Providers make any
        representations, warranties or other guarantees as to the present or future value or suitability of any sale,
        trade or other transaction involving any particular security or any other investment. Content is provided
        exclusively for personal and noncommercial access and use. No part of the Service or Content may be copied,
        reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed
        in any way (including “mirroring”) to any other computer, server, website or other medium for publication or
        distribution or for any commercial enterprise, without CCMX’s express prior written consent. You acknowledge
        that Carta is the sole owner of Carta Marks and that other Marks are the property of their respective owners.
        You agree that you will not use any Marks for any purpose without the prior express written consent of the
        respective owners.
      </p>

      <h2>Modification</h2>
      <p>
        CCMX and/or the Third Party Providers may, at any time and it their full discretion, add, modify or remove the
        Content, or any portion thereof. CCMX and/or the Third-Party Providers shall not be liable to you or any third
        party for any suspension of access or changes to the Service or the Content, or any claims related to such
        termination or suspension.
      </p>
      <p>
        You agree to indemnify and hold harmless Carta, and the Third Party Providers, for any loss or damages arising
        from or relating to such discontinuation or modification.
      </p>

      <h2>Communications</h2>
      <p>
        By using the Service or the Content, you consent to any form of recording and retention of any communication,
        information and data exchanged between you and CCMX or its representatives or agents.
      </p>
      <p>
        All communications made at or through the Forums are public. Neither CCMX nor the Third Party Providers screen,
        review, approve or endorse any Third Party Content available on or through the Forums. Reliance on any Third
        Party Content available on or through the Forums is at your own risk, and you agree to comply with any terms and
        conditions of any third party forum.
      </p>

      <h2>External Links</h2>
      <p>
        CCMX and/or the Third Party Providers may provide links to other websites or resources. Because neither CCMX or
        the Third Party Providers have any control over such sites and resources, you acknowledge and agree that neither
        CCMX nor the Third Party Providers are responsible for the availability of such external sites or resources.
        CCMX and the Third Party Providers do not endorse and are not liable for any content, advertising, products, or
        other materials on or available through such sites or resources. You further acknowledge and agree that neither
        CCMX nor the Third Party Providers shall be responsible or liable, directly or indirectly, for any damage or
        loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or
        services available on or through any such site or resource.
      </p>

      <h2>Applicable Policies</h2>
      <p>
        By using the Service, you are consenting to have your personal data transferred to and processed by Carta and
        its affiliates. As part of providing you the Service, Carta may need to provide you with certain communications,
        such as service announcements and administrative messages. These communications are considered part of the
        Service, which you may not be able to opt-out from receiving.
      </p>
    </div>
  </div>
)
